import { render, staticRenderFns } from "./login_QY.vue?vue&type=template&id=5164565c&"
import script from "./login_QY.vue?vue&type=script&lang=js&"
export * from "./login_QY.vue?vue&type=script&lang=js&"
import style0 from "./login_QY.vue?vue&type=style&index=0&id=5164565c&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports